import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        price: {
            type: Number,
            default: 0
        }
    },
    setup() { }
});
