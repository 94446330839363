import { defineComponent } from '@vue/composition-api';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { SearchRoutes } from '@/Search/search.const';
import { VenueRoutes } from '@/Venue/venue.const';
export default defineComponent({
    props: {
        space: {
            type: Object,
            default: null
        },
        venue: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const continueSearch = () => {
            if (!props.space)
                return globalThis.$router.push(useGetLocalizedPath(SearchRoutes.SEARCH));
            const query = {
                search: props.space?.venue?.venue_geo?.city?.seo_entity_name
            };
            globalThis.$router.push({
                path: useGetLocalizedPath(SearchRoutes.SEARCH),
                query
            });
        };
        return {
            VenueRoutes,
            continueSearch,
            useGetLocalizedPath
        };
    }
});
