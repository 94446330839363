import { maxBy, minBy, sortBy } from 'lodash';
import EventBus from '@/shared/services/eventBus';
import { reFormatTime } from '@/util/utils';
import { DATE_FORMATS } from '@/Calendar/const';
import Calendar from '@/Calendar/we-calendar.vue';
import { computed, defineComponent, ref, onBeforeUnmount } from '@vue/composition-api';
import { dateFormat, dateUnixTZ } from '@/util/dates';
export default defineComponent({
    components: {
        Calendar
    },
    props: {
        workTime: {
            type: Array,
            default: () => []
        },
        value: {
            type: String,
            required: true
        },
        space: {
            type: Object,
            default: () => { }
        },
        spacesCalendarData: {
            type: Array,
            default: () => []
        },
        slots: {
            type: Object,
            default: () => { }
        },
        size: { type: String, default: 'normal' }
    },
    setup(props, context) {
        const { emit } = context;
        const selectedDay = ref(null);
        const changeMode = ref(false);
        const withSlots = ref(true);
        const currentDay = computed(() => globalThis.$store.getters['$_calendar/currentDay']);
        const allSpaces = computed(() => [props.space]);
        const startDate = computed(() => {
            if (props.value === 'week') {
                return props.slots && withSlots.value
                    ? dateUnixTZ(props.slots.slotFrom, globalThis.$timezone)?.toDate()
                    : selectedDay.value;
            }
            else {
                return selectedDay.value;
            }
        });
        const formattedSchedule = computed(() => {
            let minHour, maxHour;
            if (!props.workTime.length)
                return {};
            minHour = minBy(props.workTime, e => e.open_time).open_time;
            maxHour = maxBy(props.workTime, e => e.close_time).close_time;
            const labels = [];
            let i;
            if (minHour % 60 !== 0) {
                labels.push({
                    label: reFormatTime(minHour),
                    value: minHour,
                    width: `${((minHour % 60) / 60) * 100}%`
                });
                i = minHour - (minHour % 60) + 60;
            }
            else {
                i = minHour;
            }
            if (maxHour % 60 !== 0) {
                labels.push({
                    label: reFormatTime(maxHour),
                    value: maxHour,
                    width: `${((maxHour % 60) / 60) * 100}%`
                });
            }
            while (i <= maxHour) {
                labels.push({ label: reFormatTime(i), value: i, width: '100%' });
                i += 30;
            }
            return {
                open: minHour,
                close: maxHour,
                labels: sortBy(labels, ['value'])
            };
        });
        const setCurrentDay = (date) => {
            globalThis.$store.dispatch('$_calendar/setCurrentDay', date);
        };
        const dayClick = (day) => {
            if (day === 'Invalid date') {
                // Workaround to avoid Invalid date which is coming from core.vue getEventArgs method
                // Fix in the future
                return;
            }
            EventBus.$emit('updateBookingDay', dateFormat(day, DATE_FORMATS.calendarCore));
            setCurrentDay(dateFormat(day, DATE_FORMATS.calendarCore));
            if (changeMode.value)
                emit('input', 'week');
            changeMode.value = false;
            withSlots.value = false;
            selectedDay.value = day;
        };
        const onMonthChange = (val) => {
            emit('monthChange', val);
        };
        const clearDateInfo = () => {
            globalThis.$store.dispatch('$_calendar/clearDateInfo');
        };
        const onMonthDayClick = () => {
            withSlots.value = false;
            changeMode.value = true;
        };
        onBeforeUnmount(() => {
            clearDateInfo();
        });
        return {
            allSpaces,
            changeMode,
            currentDay,
            formattedSchedule,
            startDate,
            withSlots,
            dayClick,
            onMonthChange,
            onMonthDayClick
        };
    }
});
