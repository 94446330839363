import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        title: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        avatar: {
            type: String,
            default: ''
        },
        owner: {
            type: Boolean,
            default: false
        }
    }
});
