import { render, staticRenderFns } from "./UnpaidReservationModal.vue?vue&type=template&id=96f4b6e6&scoped=true&"
import script from "./UnpaidReservationModal.vue?vue&type=script&lang=ts&"
export * from "./UnpaidReservationModal.vue?vue&type=script&lang=ts&"
import style0 from "./UnpaidReservationModal.vue?vue&type=style&index=0&id=96f4b6e6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96f4b6e6",
  null
  
)

export default component.exports