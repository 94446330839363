import { defineComponent, ref, onMounted } from '@vue/composition-api';
import VenueView from '@/pages/vendor/venues/VenueView.vue';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import getVenues from '@/api/venue/get';
import toJSON from '@/api/base/toJSON';
import getSpaces from '@/api/venue/getSpaces';
import getVenueImages from '@/api/image/getVenue';
import EmptyView from '@/components/EmptyView.vue';
import PageLoadingBar from '@/shared/components/PageLoadingBar.vue';
import { chain } from 'lodash';
export default defineComponent({
    components: {
        VenueView,
        EmptyView,
        PageLoadingBar
    },
    setup() {
        const venuesList = ref([]);
        const loaded = ref(false);
        onMounted(async () => {
            const venuesChain = chain(await toJSON(getVenues())).map(async (venue) => {
                return {
                    ...venue,
                    images: await toJSON(getVenueImages(venue.venue_id)),
                    spaces: (await toJSON(getSpaces(venue.venue_id))) || []
                };
            });
            venuesList.value = await Promise.all(venuesChain.value());
            loaded.value = true;
        });
        const createVenue = () => {
            globalThis.$router.push({
                name: ROUTE_LIST_NAMES.ONBOARDING.VENUE.MAIN
            });
        };
        return {
            loaded,
            venuesList,
            createVenue
        };
    }
});
