import get from '@/api/base/with-authorization/get';
/**
 * Fetch a list of images.
 *
 * @param {number} venueID Venue id.
 *
 * @returns {Promise}
 */
export default function (venueID) {
    return get(`image/venue/${venueID}/`);
}
