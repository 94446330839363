import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import Popover from '@/components/Popover.vue';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import { saveCurrentPath } from '@/util/utils';
import { useApplyCouponMutation, useRemoveCouponMutation, UserRoleState } from '@/generated-types/graphql.types';
import { useUserDetailsCompleted } from '@/shared/composables/useUserDetailsCompleted';
import { ServerErrors } from '@/util/graphql';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
import { PRE_SAVE_ACTION_TYPES, preSaveAction, resetPreSaveAction } from '@/util/preSaveActions';
export default defineComponent({
    components: {
        Popover
    },
    mixins: [validationMixin],
    validations: {
        form: {
            couponCode: {
                required,
                maxLength: maxLength(50)
            }
        }
    },
    props: {
        spaceId: {
            type: Number,
            default: null
        },
        spaceDisabled: {
            type: Boolean,
            default: false
        },
        coupon: {
            type: Object,
            default: null
        },
        forceApply: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const form = ref({
            couponCode: ''
        });
        const couponCode = ref('');
        const mq = computed(() => root.$mq);
        const { performAccountFlowAction } = useUserDetailsCompleted(root);
        const { mutate } = useApplyCouponMutation({});
        const { mutate: removeCoupon } = useRemoveCouponMutation({});
        const applyCoupon = () => {
            const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
            return () => {
                mutate({
                    code: couponCode.value,
                    spaceId: props.spaceId
                })
                    .then(res => {
                    const coupon = res?.data?.apply_coupon;
                    emit('onCouponAction', coupon);
                    addToast({
                        text: root.$i18n.t('coupon.applied'),
                        type: 'success',
                        dismissAfter: 5000
                    });
                })
                    .catch(({ graphQLErrors }) => {
                    const error = ServerErrors.extractGQLError(graphQLErrors);
                    if (error?.error?.status_code === 429) {
                        addToast({
                            text: root.$tc(`common.errors.${error?.error?.key}`, error.meta?.cooldown_left),
                            type: 'danger',
                            dismissAfter: 5000
                        });
                    }
                    else {
                        addToast({
                            text: root.$i18n.t(`common.errors.${error?.error?.key}`),
                            type: 'danger',
                            dismissAfter: 5000
                        });
                    }
                });
            };
        };
        const handleRemoveCoupon = () => {
            const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
            removeCoupon({ couponId: props.coupon?.coupon_id }).then(() => {
                emit('onCouponAction');
                addToast({
                    text: root.$i18n.t('coupon.deactivated'),
                    type: 'success',
                    dismissAfter: 5000
                });
            });
        };
        const onSubmit = (form) => {
            couponCode.value = form?.$model.couponCode;
            if (performAccountFlowAction.value) {
                preSaveAction({
                    type: PRE_SAVE_ACTION_TYPES.COUPON_CODE,
                    couponCode: couponCode.value
                });
                saveCurrentPath(root.$route.fullPath.slice(1));
                performAccountFlowAction.value(UserRoleState.Booker);
                return;
            }
            applyCoupon()();
            if (form)
                form.$model.couponCode = '';
        };
        watch(() => props.forceApply, (newVal, oldVal) => {
            if (newVal && newVal !== oldVal) {
                const preSavedCouponCode = localStorage.getItem('preSavedCouponCode');
                if (preSavedCouponCode) {
                    couponCode.value = preSavedCouponCode;
                    applyCoupon()();
                    resetPreSaveAction();
                }
            }
        }, { immediate: true });
        return {
            form,
            mq,
            handleRemoveCoupon,
            onSubmit
        };
    }
});
