import IncrementInput from '@/Space/components/IncrementInput.vue';
import QuestionPopover from '@/uikit/QuestionPopover.vue';
import { defineComponent, ref, watch } from '@vue/composition-api';
export default defineComponent({
    components: {
        IncrementInput,
        QuestionPopover
    },
    props: {
        item: {
            type: Object,
            required: true,
            default: () => { }
        },
        onClickAction: {
            type: Function,
            default: () => { }
        },
        slotsSelected: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: ''
        },
        mobileResponsive: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
            default: () => []
        }
    },
    setup(props) {
        const count = ref(1);
        const onCountChanged = (val) => {
            if (val)
                count.value = val;
        };
        const onClickActionEvent = (value, count) => {
            if (props.onClickAction)
                props.onClickAction(props.item, value, count);
        };
        watch(() => props.item.selected, () => {
            count.value = props.item.selected;
        }, { immediate: true });
        return {
            count,
            onClickActionEvent,
            onCountChanged
        };
    }
});
