import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    name: 'SupportedList',
    props: {
        item: {
            type: Object,
            default: () => { }
        },
        type: {
            type: String,
            default: ''
        },
        modelPropName: {
            type: String,
            default: ''
        },
        selected: {
            type: Boolean,
            default: false
        }
    }
});
