import { reactive } from '@vue/composition-api';
import { dateFormat } from '@/util/dates';
import qs from 'query-string';
import { getStorageToken } from '@/Authentication/utils/storage';
export class SpacePageState {
    state;
    constructor() {
        this.state = reactive({
            modelPropNames: {
                facility: 'system_service_name',
                feature: 'feature_name',
                configuration: 'configuration_type',
                activity: 'category_name'
            },
            isMobileBookingPanel: false,
            isShowImageViewer: false,
            showUnpaidReservationModal: false,
            showBookingConfirmationModal: false,
            error: {
                text: '',
                type: ''
            },
            selectedDay: dateFormat('', 'YYYY-MM-DD')
        });
    }
}
export class BookingSummaryState {
    summary;
    constructor() {
        this.summary = reactive({
            space: {},
            attendees_max: 0,
            attendees_selected_count: 0,
            slot_start: 0,
            slot_end: 0,
            is_discount_applied: false,
            is_coupon_applied: false,
            discount_rate: null,
            order: {
                discount_total: null,
                order_items: [],
                order_total: null,
                order_total_vat_excl: null
            },
            vat_21: null,
            vat_9: null,
            total_vat_excl: null,
            total: null
        });
    }
    /**
     * @description
     * Get cached booking summary from local storage.
     * Set booking summary to reactive summary object if cache found.
     * @param spaceId
     * @param discountApplied
     */
    getCachedSummary(spaceId, discountApplied) {
        if (process.browser) {
            if (getStorageToken()) {
                const bookingFromStorage = localStorage.getItem('cachedBookingSummary');
                const booking = bookingFromStorage
                    ? JSON.parse(bookingFromStorage)
                    : undefined;
                if (booking && spaceId === booking?.space.space_id) {
                    Object.assign(this.summary, booking);
                    discountApplied.value = booking.is_discount_applied;
                    localStorage.removeItem('cachedBookingSummary');
                    return this.summary;
                }
                return null;
            }
            else {
                localStorage.removeItem('cachedBookingSummary');
            }
        }
        return null;
    }
}
export class SpacePageQueryParams {
    params;
    constructor() {
        this.params = reactive({
            slotStart: null,
            slotEnd: null,
            configuration: null,
            isDiscountRequested: false,
            facilities: [],
            caterings: [],
            couponDiscount: null,
            couponId: null,
            attendees: null,
            editable: false
        });
    }
    /**
     * @description
     * Read query params from url and set them to reactive params object.
     */
    readQuery() {
        const currentQueryParams = qs.parse(location.search, {
            arrayFormat: 'bracket'
        });
        for (const key in currentQueryParams) {
            if (Object.prototype.hasOwnProperty.call(currentQueryParams, key) &&
                key in this.params) {
                if (['facilities', 'caterings'].includes(key)) {
                    const services = [];
                    for (const service of currentQueryParams[key]) {
                        const [service_name, referenceId, quantity, is_mandatory, system_service_id] = service.split('+');
                        services.push({
                            service_name,
                            referenceId: parseInt(referenceId),
                            quantity: parseInt(quantity),
                            is_mandatory: JSON.parse(is_mandatory),
                            system_service_id: parseInt(system_service_id)
                        });
                    }
                    this.params[key] = services;
                }
                else {
                    this.params[key] = JSON.parse(currentQueryParams[key]);
                }
            }
        }
    }
}
export class StaticVenueServices {
    services;
    constructor() {
        this.services = reactive({
            facilities: [],
            catering: []
        });
    }
}
export class AvailableVenueServices {
    services;
    constructor() {
        this.services = reactive({
            facilities: [],
            catering: [],
            updated: false
        });
    }
}
export class AvailableSpaceServices {
    services;
    constructor() {
        this.services = reactive({
            default: [],
            onDemand: [],
            updated: false
        });
    }
}
