import { useGetPreliminaryBookingCalculationsMutation, OrderItemType, SystemServiceType, UserRoleState, SpaceChatDocument, ChatMessageType } from '@/generated-types/graphql.types';
import { reactive } from '@vue/composition-api';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
import equal from 'lodash/isEqual';
import { saveCurrentPath } from '@/util/utils';
import qs from 'query-string';
import { ServerErrors } from '@/util/graphql';
import { ChatRoutes } from '@/Chat/chat.const';
import { PRE_SAVE_ACTION_TYPES, preSaveAction, resetPreSaveAction } from '@/util/preSaveActions';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import { apolloClient } from '@/main';
/**
 * @description Get booking calculations by calling the respective GraphQL mutation
 */
export const useBookingCalculations = (context) => {
    const calculationInput = reactive({
        input: {
            booking_start: null,
            booking_end: null,
            is_discount_requested: false,
            order_items: [],
            coupon_id: undefined
        }
    });
    const { updateQueryParams } = useUpdateQueryParams(context);
    const { mutate: getBookingCalculations, onDone: onCalculationsDone } = useGetPreliminaryBookingCalculationsMutation({});
    const restoreCalculationInput = (bookingSummary) => {
        calculationInput.input.order_items = [];
        bookingSummary.order.order_items.forEach((item) => {
            calculationInput.input.order_items.push({
                reference_id: item.reference_id,
                order_item_type: item.order_item_type,
                quantity: item.quantity
            });
        });
    };
    const executeCalculations = (args) => {
        calculationInput.input.is_discount_requested = args.isDiscountApplied;
        calculationInput.input.coupon_id = args.couponId;
        calculationInput.input.booking_edit_mode = args.editable
            ? {
                booking_id: +sessionStorage.getItem('bookingId')
            }
            : null;
        updateQueryParams({
            bookingSummary: args.bookingSummary,
            editable: args.editable,
            couponDiscount: args.couponDiscount,
            couponId: args.couponId,
            bookingOrigin: args.bookingOrigin,
            isRequestBookingUpdateButtonDisabled: args.isRequestBookingUpdateButtonDisabled
        });
        restoreCalculationInput(args.bookingSummary);
        getBookingCalculations(calculationInput).catch(({ graphQLErrors }) => {
            if (graphQLErrors) {
                const error = ServerErrors.extractGQLError(graphQLErrors);
                // TODO: Handle error
                console.log(error);
            }
        });
    };
    return {
        calculationInput,
        executeCalculations,
        onCalculationsDone
    };
};
/**
 * @description Copy booking setup link to clipboard
 */
export const useCopyLink = (context) => {
    const { root } = context;
    const copyLink = (spaceId) => {
        const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
        root.$copyText(`${window.location.href}`);
        addToast({
            text: root.$i18n.t('common.social_sharing.link_copied'),
            type: 'success',
            dismissAfter: 4000
        });
        globalThis.$gtm.trackEvent({
            event: 'booking_share_button',
            category: 'space',
            action: 'booking_share_button_clicked',
            label: undefined,
            value: spaceId,
            noninteraction: false
        });
    };
    return { copyLink };
};
/**
 * @description
 * Update query params on every booking details re-calculation.
 * For services (catering, facility) the URL structure is as below:
 *       - service name + service reference ID + quantity.
 *       For example,
 *       selected services: dinner x 3 pcs., printer x 1 pcs.
 *       URL structure: '&caterings[]=dinner+1+3&facilities[]=printer+13+1'
 *
 * Full structure of the URL query for selected booking:
 *       '?caterings[]=dinner_vegetarian+3+3&configuration=7&facilities[]=sound_system_excl_mic+1+2
 *        &isDiscountRequested=true&slotEnd=1652032800&slotStart=1652029200'
 */
export const useUpdateQueryParams = (context) => {
    const { root } = context;
    const updateQueryParams = (args) => {
        const currentQuery = root.$route.query;
        const queryPrefix = currentQuery.length ? '&' : '?';
        const queryParams = {
            slotStart: args.bookingSummary.slot_start,
            slotEnd: args.bookingSummary.slot_end,
            attendees: args.bookingSummary.attendees_selected_count,
            isDiscountRequested: args.bookingSummary.is_discount_applied,
            configuration: args.bookingSummary.order.order_items.find((item) => item.order_item_type === OrderItemType.Configuration)?.reference_id,
            'facilities[]': args.bookingSummary.order.order_items
                .filter((item) => {
                return (item.order_item_type === OrderItemType.Service &&
                    item.service_type === SystemServiceType.Facility);
            })
                .map((item) => `${item.name}+${item.reference_id}+${item.quantity}+${item.is_mandatory}+${item.system_service_id}`),
            'caterings[]': args.bookingSummary.order.order_items
                .filter((item) => {
                return (item.order_item_type === OrderItemType.Service &&
                    item.service_type === SystemServiceType.Catering);
            })
                .map((item) => `${item.name}+${item.reference_id}+${item.quantity}+${item.is_mandatory}+${item.system_service_id}`),
            editable: args.editable,
            couponDiscount: args.couponDiscount,
            couponId: args.couponId
        };
        if (args.editable && args.bookingOrigin) {
            args.isRequestBookingUpdateButtonDisabled.value = equal(queryParams, args.bookingOrigin);
        }
        saveCurrentPath(root.$route.fullPath.slice(1) +
            `${queryPrefix}${qs.stringify(queryParams)}`);
        history.replaceState({}, null, `${queryPrefix}${qs.stringify(queryParams)}`);
    };
    return { updateQueryParams };
};
export const useSpaceChat = (context, performAccountFlowAction) => {
    const openChat = (args) => {
        const { root } = context;
        resetPreSaveAction();
        return async () => {
            const { data, errors } = await apolloClient.query({
                query: SpaceChatDocument,
                variables: {
                    id: args.space.space_id,
                    chatEventName: args.chatMessageType
                },
                fetchPolicy: 'no-cache'
            });
            if (!errors && data?.get_chat) {
                globalThis.$router.push(useGetLocalizedPath(`${ChatRoutes.CHAT}/${data.get_chat.channel_id}`));
            }
            if (errors) {
                const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
                addToast({
                    text: root.$i18n.t('common.errors.something_wrong'),
                    type: 'danger',
                    dismissAfter: 5000
                });
            }
        };
    };
    const createChat = (args) => {
        const { root } = context;
        // Callback for unauthorized users
        resetPreSaveAction();
        if (performAccountFlowAction.value) {
            preSaveAction({
                type: PRE_SAVE_ACTION_TYPES.CHAT,
                space: args.space,
                chatType: args.chatMessageType
            });
            saveCurrentPath(root.$route.fullPath.slice(1));
            performAccountFlowAction.value(UserRoleState.Booker);
            return;
        }
        // Send event
        globalThis.$gtm.trackEvent({
            event: args.chatMessageType === ChatMessageType.SpaceVisitRequested
                ? 'space_visit_request'
                : 'space_chat_opened',
            category: 'consideration',
            action: 'chat_opened',
            label: undefined,
            value: args.space.space_name || '',
            noninteraction: false
        });
        openChat(args)();
    };
    return { createChat };
};
