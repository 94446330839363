import EventBus from '@/shared/services/eventBus';
import Popover from '@/components/Popover.vue';
import { defineComponent, onMounted, ref, watch, computed } from '@vue/composition-api';
export default defineComponent({
    name: 'IncrementInput',
    components: { Popover },
    props: {
        buttonLabel: {
            type: String,
            required: false,
            default: ''
        },
        onClickAction: {
            type: Function,
            default: () => { }
        },
        onCountChanged: {
            type: Function,
            default: () => { }
        },
        expectQuantity: {
            type: Boolean,
            default: false
        },
        maxInventory: {
            type: [Number, Boolean],
            default: false
        },
        remainingInventory: {
            type: Number,
            default: 0
        },
        selected: {
            type: Number,
            default: 0
        },
        serviceId: {
            type: Number,
            default: null
        },
        inventoryEnabled: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        },
        orderType: {
            type: String,
            default: ''
        },
        popoverContent: {
            type: String,
            default: ''
        },
        countUpdate: {
            type: Number,
            default: 0
        }
    },
    setup(props) {
        const isSelected = ref(props.selected > 0);
        const count = ref(props.selected);
        const buttonColor = ref('');
        watch(() => props.countUpdate, newValue => {
            if (newValue)
                count.value = newValue;
        });
        /**
         * Define when the increment button is disabled. It is disabled when the service expects a quantity and:
         * - Max inventory limit for the service is not set and inventory is enabled
         * - Service quantity is 0
         * - Service quantity is greater than the max inventory limit
         */
        const isButtonDisabled = computed(() => {
            return (props.expectQuantity &&
                ((!props.maxInventory && props.inventoryEnabled) ||
                    (!isSelected.value &&
                        count.value !== 1 &&
                        count.value > props.maxInventory &&
                        props.maxInventory !== 0) ||
                    (props.maxInventory !== false &&
                        props.maxInventory !== props.remainingInventory &&
                        props.remainingInventory === 0) ||
                    count.value === 0));
        });
        const incrementCount = () => {
            if (count.value === 999 ||
                isSelected.value ||
                (props.maxInventory !== 0 && count.value === props.maxInventory) ||
                (props.maxInventory !== props.remainingInventory &&
                    props.remainingInventory === 0)) {
                return;
            }
            count.value += 1;
            if (props.onCountChanged) {
                props.onCountChanged(count.value);
            }
        };
        const decrementCount = () => {
            if (isSelected.value && !props.error)
                return;
            count.value -= 1;
            if (count.value < 1) {
                isSelected.value = false;
                count.value = 0;
            }
            if (props.onCountChanged) {
                props.onCountChanged(count.value);
            }
        };
        const onKeyDown = e => {
            return [69, 187, 188, 189, 190].includes(e.keyCode) && e.preventDefault();
        };
        const updateInputValue = (e) => {
            if (!e.target.value.length) {
                e.target.value = '0';
            }
            else if (e.target.value.length > 1 && e.target.value.startsWith('0')) {
                e.target.value = e.target.value.slice(1);
            }
            else if (e.target.value.length > 3) {
                e.target.value = e.target.value.slice(0, 3);
            }
            if (+e.target.value > props.maxInventory &&
                typeof props.maxInventory === 'number' &&
                props.inventoryEnabled) {
                e.target.value = props.maxInventory.toString();
            }
            count.value = +e.target.value;
            if (props.onCountChanged) {
                props.onCountChanged(count.value);
            }
        };
        const onClick = () => {
            isSelected.value = !isSelected.value;
            if (isSelected.value && count.value === 0) {
                count.value = 1;
            }
            if (props.onClickAction) {
                props.onClickAction(isSelected.value, count.value);
            }
        };
        const minDisabled = () => {
            if (props.error)
                return false;
            else
                return isSelected.value || count.value === 0;
        };
        watch(() => props.error, () => {
            if (props.error) {
                isSelected.value = false;
                count.value = 0;
            }
        }, { immediate: true });
        watch(() => isSelected.value, () => {
            if (isSelected.value && count.value > 0) {
                buttonColor.value = 'danger';
            }
            else {
                buttonColor.value = 'accent';
            }
        }, { immediate: true });
        onMounted(() => {
            EventBus.$on('removeService', item => {
                if (item.reference_id === props.serviceId) {
                    isSelected.value = false;
                }
            });
        });
        return {
            buttonColor,
            count,
            isButtonDisabled,
            isSelected,
            decrementCount,
            incrementCount,
            minDisabled,
            onClick,
            onKeyDown,
            updateInputValue
        };
    }
});
