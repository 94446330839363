import { currencyFilter } from '@/util/templateFilters';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import { useOrganisationServiceFeeQuery } from '@/generated-types/graphql.types';
import { withAuthQueryHook } from '@/util/hooks';
import { computed, defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        space: {
            type: Object,
            default: () => { }
        },
        venueId: {
            type: Number,
            default: null
        }
    },
    setup(props, context) {
        const { root } = context;
        const { result } = withAuthQueryHook(useOrganisationServiceFeeQuery)({}, {
            clientId: 'legacy'
        });
        const vatRate = computed(() => `${result?.value?.viewerApiKey?.getApiOrganisation?.vatRate}% `);
        const price = computed(() => parseFloat(props.space?.half_day_price) || 0);
        const tags = computed(() => {
            const priceTagParams = {
                price: currencyFilter(price.value),
                rate: vatRate.value
            };
            return [
                `${root.$tc('spaces.venue.price_tag_label', 0, priceTagParams)}`,
                `${props.space?.max_guests} ${root.$i18n.t('spaces.venue.max_guests')}`,
                `${props.space?.space_categories?.length} ${root.$i18n.t('spaces.venue.activities')}`
            ];
        });
        const advertisedImage = computed(() => {
            const img = props.space?.space_images?.find(el => el.order === 0)?.image_url +
                '?tr=w-120'; // Use 120px width thumbnail
            return img || '/img/common/placeholder-image.png';
        });
        const openSpaceSettings = () => {
            globalThis.$router.push({
                name: ROUTE_LIST_NAMES.SPACE_SETTINGS.MAIN,
                params: { venueId: props.venueId, spaceId: props.space?.space_id }
            });
        };
        const changeStatus = () => {
            globalThis.$router.push({
                name: ROUTE_LIST_NAMES.SPACE_SETTINGS.MAIN,
                params: {
                    venueId: props.venueId,
                    spaceId: props.space?.space_id
                },
                query: {
                    status: true
                }
            });
        };
        return {
            advertisedImage,
            price,
            tags,
            vatRate,
            changeStatus,
            openSpaceSettings
        };
    }
});
