var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"increment-input"},[(_vm.expectQuantity)?_c('div',{staticClass:"content-count"},[_c('div',{staticClass:"count-manipulations"},[_c('i',{class:[
          'fa-solid fa-circle-minus fa-sm',
          { disabled: _vm.minDisabled() }
        ],on:{"click":_vm.decrementCount}}),_c('div',{staticClass:"input-place"},[(_vm.isSelected)?_c('span',{staticClass:"content-count-number"},[_vm._v(" "+_vm._s(_vm.count)+" ")]):_vm._e(),(!_vm.isSelected)?_c('input',{attrs:{"name":"incrementInput","type":"number","min":"0","step":"1"},domProps:{"value":_vm.count},on:{"input":_vm.updateInputValue,"keydown":_vm.onKeyDown}}):_vm._e()]),_c('i',{class:[
          'fa-solid fa-circle-plus fa-sm',
          {
            disabled:
              _vm.isSelected ||
              (_vm.count === _vm.maxInventory && _vm.maxInventory !== 0) ||
              (_vm.maxInventory !== _vm.remainingInventory &&
                _vm.remainingInventory === 0)
          }
        ],on:{"click":_vm.incrementCount}})])]):_vm._e(),(_vm.expectQuantity && _vm.popoverContent && !(_vm.$mq && _vm.$mq.phone))?_c('Popover',{attrs:{"offset":[-135, -50]}},[_c('div',{staticClass:"popover-icon"},[_c('i',{staticClass:"fa-solid fa-circle-question fa-xs"})]),_c('div',{staticClass:"popover-content",attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.popoverContent)+" ")])]):_vm._e(),(_vm.buttonLabel)?_c('WMButton',{key:_vm.isSelected,staticClass:"content-action",attrs:{"clear":!_vm.isSelected,"disabled":_vm.isButtonDisabled,"color":_vm.buttonColor,"small":"","icon-class-name":("" + (_vm.isSelected ? 'fa-solid fa-minus fa-xs' : 'fa-solid fa-plus fa-xs'))},on:{"click":_vm.onClick}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(" "+_vm._s(_vm.isSelected ? _vm.$t('customer.space.additional_facilities.added') : _vm.buttonLabel)+" ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }