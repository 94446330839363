var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['medium-list-item', { 'no-slots': !_vm.slotsSelected }],attrs:{"id":("service-" + (_vm.item.system_service_id))}},[_c('div',{staticClass:"content",class:{ 'mobile-responsive': _vm.mobileResponsive }},[_c('div',{class:['content-left', { 'no-slots': !_vm.slotsSelected }]},[_c('div',{class:[
          'content-left__service',
          {
            error: _vm.errors.includes(_vm.item.system_service_id),
            'no-slots': !_vm.slotsSelected
          }
        ]},[_c('div',{class:['content-left__service__content']},[_c('i',{staticClass:"content-left__service__content__icon",class:("fa-solid fa-" + (_vm.$t(
              ("filter.facility." + (_vm.item.system_service_name) + ".icon")
            )) + " fa-2xl")}),_c('div',{staticClass:"content-left__service__content__text"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(("filter.facility." + (_vm.item.system_service_name) + ".title")))+" "),(
                  _vm.$te(
                    ("filter.facility." + (_vm.item.system_service_name) + ".description")
                  )
                )?_c('QuestionPopover',{attrs:{"styles":'margin: 5px 5px 0',"description":_vm.$t(
                    ("filter.facility." + (_vm.item.system_service_name) + ".description")
                  ).toString()}}):_vm._e(),(_vm.item.description)?_c('QuestionPopover',{attrs:{"styles":'margin: 5px 5px 0',"description":_vm.item.description}}):_vm._e()],1)])])]),_c('div',{staticClass:"content-left__extra-info"},[(_vm.item.venue_service_order_type)?_c('span',{staticClass:"content-left__extra-info__price"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.item.price))+" "+_vm._s(_vm.item.price > 0 ? _vm.$t( ("filter.facility.order_type." + (_vm.item.venue_service_order_type.toLowerCase())) ) : '')+" ")]):_vm._e(),(
            _vm.item.venue_service_id &&
            _vm.item.remaining_inventory !== undefined &&
            _vm.item.max_inventory
          )?_c('span',{class:[
            'content-left__extra-info__availability',
            { error: _vm.errors.includes(_vm.item.system_service_id) }
          ]},[_vm._v(" "+_vm._s(_vm.$t('inventory_management.maximum_inventory_limit', { x: _vm.item.remaining_inventory }))+" ")]):_vm._e(),(_vm.item.is_mandatory)?_c('span',{staticClass:"content-left__extra-info__required"},[_vm._v(" "+_vm._s(_vm.$t('customer.space.additional_facilities.required_extra_service'))+" ")]):_vm._e()])]),(_vm.item.venue_service_id && _vm.item.remaining_inventory !== undefined)?_c('div',{staticClass:"content-right"},[_c('IncrementInput',{attrs:{"button-label":_vm.$t('customer.space.additional_facilities.price_label').toString(),"inventory-enabled":_vm.item.is_inventory_enabled,"expect-quantity":_vm.item.expect_quantity,"on-click-action":_vm.onClickActionEvent,"on-count-changed":_vm.onCountChanged,"max-inventory":_vm.item.expect_quantity && _vm.item.max_inventory,"order-type":_vm.item.venue_service_order_type,"popover-content":_vm.$t(
            ("inventory_management.modal.order_type." + (_vm.item.venue_service_order_type.toLowerCase()) + ".description")
          ),"selected":_vm.item.selected,"remaining-inventory":_vm.item.remaining_inventory,"service-id":_vm.item.system_service_id,"error":_vm.errors.includes(_vm.item.system_service_id)}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }