import { capitalize } from 'lodash';
import { SITE_URL } from '@/App.vue';
import { reFormatTime } from '@/util/utils';
import { enumDayOfWeek } from '@/util/dates';
import { SpaceRoutes } from '@/Space/space.const';
export const spaceSchemaOrgInfo = (space) => {
    const title = space.internal_name;
    const images = space?.space_images?.map(image => image?.image_url);
    const geo = space?.venue?.venue_geo;
    const openingHours = space?.venue?.working_time?.map(workingDay => ({
        '@type': 'OpeningHoursSpecification',
        // @ts-ignore
        dayOfWeek: capitalize(enumDayOfWeek.get(workingDay.week_day)),
        // @ts-ignore
        opens: reFormatTime(workingDay.open_time),
        // @ts-ignore
        closes: reFormatTime(workingDay.close_time)
    }));
    // get the end of the current year, format it to YYYY-MM-DD
    const currentYear = new Date().getFullYear();
    const endOfYear = new Date(currentYear, 11, 31);
    const endOfYearFormatted = endOfYear.toISOString().split('T')[0];
    return {
        '@type': ['MeetingRoom', 'Product'],
        name: title,
        description: space?.space_description,
        image: images,
        geo: {
            '@type': 'GeoCoordinates',
            latitude: geo.latitude,
            longitude: geo.longitude
        },
        address: {
            '@type': 'PostalAddress',
            streetAddress: geo.address,
            addressLocality: geo.city.seo_entity_name,
            addressRegion: geo.region.seo_entity_name,
            postalCode: geo.postal_code
        },
        url: `${SITE_URL}/${SpaceRoutes.SPACE}${space?.space_url}`,
        branchCode: space?.space_reference,
        openingHoursSpecification: openingHours,
        offers: {
            '@type': 'Offer',
            price: space?.short_day_price,
            priceCurrency: 'EUR',
            availability: 'PreOrder',
            priceValidUntil: endOfYearFormatted,
            hasMerchantReturnPolicy: {
                '@type': 'MerchantReturnPolicy',
                returnPolicyCategory: 'In-Store Only',
                applicableCountry: 'NL',
            }
        },
        gtin13: space?.space_url
    };
};
