var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"address-bar"},[_c('div',{staticClass:"address-bar-wrapper"},[_c('div',{staticClass:"title-block"},[_c('h2',{ref:"seoSpaceH2",attrs:{"contenteditable":_vm.user && _vm.user.is_admin},domProps:{"innerHTML":_vm._s(_vm.spaceSeo.h2)},on:{"input":function($event){return _vm.handleAdminUpdates('seoSpaceH2')},"keydown":function($event){return _vm.$emit('handle-key-down', $event)}}}),_c('p',{ref:"seoSpaceDescription",attrs:{"contenteditable":_vm.user && _vm.user.is_admin},domProps:{"innerHTML":_vm._s(_vm.spaceSeo.description)},on:{"input":function($event){return _vm.handleAdminUpdates('seoSpaceDescription')},"keydown":function($event){return _vm.$emit('handle-key-down', $event)}}})]),_c('div',{staticClass:"address-bar__address"},[(_vm.space.venue.venue_geo.city.seo_entity_slug)?_c('router-link',{staticClass:"link-underline",attrs:{"to":_vm.useGetLocalizedPath(
            ("" + (_vm.GeoPagesRoutes.CITIES) + (_vm.space.venue.venue_geo.city.seo_entity_slug))
          ),"target":"_blank"}},[_c('span',{staticClass:"address-bar__address-text"},[_vm._v(_vm._s(_vm.space.venue.venue_geo.address))])]):_vm._e(),_c('WMButton',{staticClass:"show-on-map",attrs:{"data-gtm":"show_space_on_map_button","small":"","color":"primary","icon-class-name":"fa-solid fa-location-dot fa-xs"},on:{"click":function($event){return _vm.$emit('show-map')}}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('common.show_on_map')))])]),(!_vm.user || _vm.user.account_state === _vm.UserRoleState.Booker)?_c('WMButton',{staticClass:"space-visit-button",attrs:{"data-gtm":"space_visit_request_button","small":"","color":"accent","disabled":_vm.space && !_vm.space.is_active,"icon-class-name":"fa-solid fa-building-circle-check fa-xs"},on:{"click":function($event){return _vm.$emit('space-visit-button-clicked')}}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('space.visit_request.button_label')))])]):_vm._e()],1)]),_c('div',{staticClass:"address-bar__buttons-block"},[(_vm.space.venue.venue_geo.city.seo_entity_slug)?_c('router-link',{attrs:{"to":_vm.useGetLocalizedPath(
          ("" + (_vm.GeoPagesRoutes.CITIES) + (_vm.space.venue.venue_geo.city.seo_entity_slug))
        )}},[(_vm.space.venue.venue_geo.city.seo_entity_name)?_c('WMButton',{staticClass:"address-bar-button",attrs:{"data-gtm":"visit_more_button","small":"","color":"accent","icon-class-name":"fa-solid fa-magnifying-glass fa-lg"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('space.details.visit_more', { city_name: _vm.space.venue.venue_geo.city.seo_entity_name }))+" ")])]):_vm._e()],1):_vm._e(),(
        !_vm.user ||
        (_vm.user &&
          _vm.user.account_state === _vm.UserRoleState.Booker &&
          !_vm.space.is_current_user_owner)
      )?_c('WMButton',{staticClass:"address-bar-button contact-person",attrs:{"data-gtm":"contact_space_host_button","small":"","color":"primary","disabled":_vm.space && !_vm.space.is_active,"icon-class-name":"fa-solid fa-comment fa-lg"},on:{"click":function($event){return _vm.$emit('chat-button-clicked')}}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('customer.venue.details.contact_person')))])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }