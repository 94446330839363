import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        description: {
            type: String,
            default: ''
        },
        instructions: {
            type: String,
            default: ''
        },
        size: {
            type: Number,
            default: 0
        },
        isSpace: {
            type: Boolean,
            default: false
        },
        spaceName: {
            type: String,
            default: ''
        },
        cityName: {
            type: String,
            default: ''
        },
        isAfterpayAllowed: {
            type: Boolean,
            default: false
        }
    }
});
