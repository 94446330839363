import { defineComponent } from '@vue/composition-api';
import Popover from '@/components/Popover.vue';
export default defineComponent({
    components: {
        Popover
    },
    props: {
        space: {
            type: Object,
            default: () => { }
        },
        applied: {
            type: Boolean,
            default: false
        }
    },
    setup(_, { emit }) {
        const selectedDiscount = () => {
            emit('discountSelected');
        };
        return {
            selectedDiscount
        };
    }
});
