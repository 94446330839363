import { defineComponent, ref, computed } from '@vue/composition-api';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import { get } from 'lodash';
import SpaceListItem from '@/pages/vendor/venues/SpaceListItem.vue';
export default defineComponent({
    components: {
        SpaceListItem
    },
    props: {
        venue: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const noSpacesVisible = ref(true);
        const venuesList = computed(() => globalThis.$store.getters['$_vendor/venuesList']);
        const createSpace = () => {
            localStorage.setItem('venue_id', JSON.stringify(props.venue.venue_id));
            // Clean space details before creating a new space
            globalThis.$store.commit('$_vendor/CLEAN_SPACE_DATA');
            globalThis.$router.push({
                name: ROUTE_LIST_NAMES.ONBOARDING.SPACE.MAIN,
                params: { venueId: props.venue.venue_id }
            });
        };
        const openVenueSettings = () => {
            globalThis.$router.push({
                name: ROUTE_LIST_NAMES.VENUE_SETTINGS.MAIN,
                params: { venueId: props.venue.venue_id }
            });
        };
        const toggleNoSpaces = () => {
            noSpacesVisible.value = !noSpacesVisible.value;
        };
        return {
            noSpacesVisible,
            venuesList,
            createSpace,
            openVenueSettings,
            toggleNoSpaces
        };
    },
    computed: {
        spaces() {
            return get(this, 'venue.spaces', []);
        }
    }
});
