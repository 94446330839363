import CalendarBlock from '@/Calendar/components/CalendarBlock.vue';
import Toggle, { Option } from '@/uikit/Toggle.vue';
import { computed, defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api';
import { useGetSpacesCalendarDataQuery } from '@/generated-types/graphql.types';
import { now } from '@/util/dates';
export default defineComponent({
    components: {
        CalendarBlock,
        Toggle,
        Option
    },
    props: {
        editable: {
            type: Boolean,
            required: true
        },
        workTime: {
            type: Array,
            default: () => []
        },
        space: {
            type: Object,
            default: () => { }
        },
        slots: {
            type: Object,
            default: () => { }
        }
    },
    setup(props) {
        const month = ref(now.month() + 1); // months in Moment().month() start with 0
        const year = ref(now.year());
        const spacesCalendarDataVariables = reactive({
            months: [month.value],
            spaceIds: null,
            year: year.value,
            bookingEditMode: null
        });
        const spacesCalendarDataOptions = reactive({
            enabled: false,
            fetchPolicy: 'no-cache'
        });
        const { result: getSpacesCalendarData, refetch: refetchSpacesCalendarData } = useGetSpacesCalendarDataQuery(spacesCalendarDataVariables, spacesCalendarDataOptions);
        const spacesCalendarData = computed(() => {
            return getSpacesCalendarData?.value?.get_spaces_calendar_data;
        });
        onMounted(() => {
            if (props.editable) {
                spacesCalendarDataVariables.bookingEditMode = {
                    booking_id: +sessionStorage.getItem('bookingId')
                };
            }
        });
        watch(() => props.space, () => {
            if (props.space) {
                spacesCalendarDataVariables.spaceIds = [props.space.space_id];
                spacesCalendarDataOptions.enabled = true;
                refetchSpacesCalendarData(spacesCalendarDataVariables);
            }
        }, { immediate: true });
        const onMonthChange = (_date) => {
            if (_date && (_date.month !== month.value || _date.year !== year.value)) {
                month.value = _date.month;
                year.value = _date.year;
                spacesCalendarDataVariables.months = [month.value];
                spacesCalendarDataVariables.year = year.value;
                spacesCalendarDataOptions.enabled = true;
                refetchSpacesCalendarData(spacesCalendarDataVariables);
            }
        };
        return { mode: 'week', spacesCalendarData, onMonthChange };
    }
});
